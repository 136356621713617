// mdcコンポーネント以降のテーマ設定
@use '@angular/material' as mat;
@use '../../../styles/themes/custom-theme';

// mdcコンポーネント以前のテーマ/スタイル 設定
@import 'base';
@import '@angular/cdk/overlay-prebuilt.css';
// @import '../../variables/colors';

.looks-search {
  @import 'dialog';
  @import 'tooltip';
  @import 'form';
  @import 'menu';
}

.release-note-dialog-buttons .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.grid {
  padding: 0 !important;
}

.custom-snackbar {
  background: rgba($basic-planning, 0.72);
  color: #fafafa;
  text-align: center;
  .mat-simple-snackbar-action {
    color: #fff; /* 好みの色に変更 */
  }
}

.custom-tooltip {
  white-space: pre-line;
}

.pagination {
  left: 50%;
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #aaa;
    z-index: 100;
  }
  .swiper-pagination-bullet-active {
    background: #0090ff;
  }
}

.pulldown-button-menu {
  border-radius: 4px !important;
  .mat-menu-content {
    padding: 8px 0 !important;
  }
  .mat-menu-content,
  .mat-menu-item {
    color: #585858;
    font-size: 14px;
  }
}

.cdk-row .joinery-row {
  &.multi-child {
    background: #f2fafa !important;
    height: 0 !important;
  }
}

button[mat-icon-button] {
  $large-size-button: 80px;
  $large-size-icon: 48px;

  &.icon-button-large {
    width: $large-size-button;
    height: $large-size-button;
    line-height: $large-size-button;
    .mat-icon {
      font-size: $large-size-icon;
      width: $large-size-icon;
      height: $large-size-icon;
      line-height: $large-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  $small-size-button: 24px;
  $small-size-icon: 18px;

  &.icon-button-small {
    width: $small-size-button;
    height: $small-size-button;
    line-height: $small-size-button;
    .mat-icon {
      font-size: $small-size-icon;
      width: $small-size-icon;
      height: $small-size-icon;
      line-height: $small-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
}

.xr-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #fff;
  }
}

.g-joinery-picklist-index-slide-toggle {
  .mat-slide-toggle-label {
    justify-content: space-between;
  }
}

ls-joinery-picklist-index .picklist-index .mat-expansion-panel-body {
  padding: 0;
}

ls-joinery-picklist-index
  .accordion-item
  .mat-expansion-panel-header.mat-expanded {
  height: 48px;
}

.hide-deferred-select-option {
  display: none !important;
  display: flex;
  align-items: center;
}

.mat-drawer-container {
  background: #fff !important;
}

gn-picklist-tree .mat-expansion-panel-header.mat-expanded {
  height: 40px;
}

gn-picklist-tree .mat-expansion-panel-header {
  height: 40px;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: none;
}

ls-joinery-table .mat-select-value-text {
  white-space: normal !important;
}

ls-joinery-table .mat-select-value {
  white-space: normal !important;
}

ls-joinery-table .mat-select-trigger {
  height: fit-content !important;
}
.menu-inner .mat-menu-item {
  overflow: initial;
}

.mat-horizontal-stepper-wrapper {
  gap: 24px;
}

gn-shipping-address-forms .mat-radio-label-content {
  width: 100%;
}

gn-shipping-address-forms label.mat-radio-label {
  align-items: center;
}

.sample-request-menu-button-panel {
  max-height: 320px;
  * {
    font-size: 14px !important;
  }
}

// サンプル請求カートのstepperのヘッダーのスタイル
.mat-horizontal-stepper-header-container {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 10;
}

// 個人サンプル請求カートのstepperのヘッダーの位置調整
gn-private-sample-request-steps-container {
  .mat-horizontal-stepper-header-container {
    top: $global-nav-height;
  }
}
gn-project-sample-request-steps-container {
  // 35pxはalertの高さ
  .mat-horizontal-stepper-header-container:has(.alert) {
    top: calc($global-nav-height + $sub-header-height + 35px);
  }
}

// プロジェクトサンプル請求カートのstepperのヘッダーの位置調整
gn-project-sample-request-steps-container {
  .mat-horizontal-stepper-header-container {
    top: calc($global-nav-height + $sub-header-height);
  }
}
gn-project-sample-request-steps-container {
  // 35pxはalertの高さ
  .mat-horizontal-stepper-header-container:has(.alert) {
    top: calc($global-nav-height + $sub-header-height + 35px);
  }
}

.mat-badge-content {
  color: #fff !important;
}

.cdk-overlay-backdrop {
  background-color: transparent !important;
}

admin-account-container .mat-column-audience {
  max-width: 300px !important;
  min-width: 300px !important;
}

ls-joinery-table .mat-select-value-text {
  white-space: normal !important;
}

ls-joinery-table .mat-select-value {
  white-space: normal !important;
}

ls-joinery-table .mat-select-trigger {
  height: fit-content !important;
}

.mat-progress-bar-fill::after {
  background: linear-gradient(
    90deg,
    rgba(0, 212, 255, 1) 0% rgba(13, 13, 184, 1) 35%,
    rgba(20, 11, 168, 1) 100%
  );
}

gn-search-side-nav-view,
gn-search-params-list-container {
  .mat-expansion-panel-header {
    font-size: 13px;
    font-weight: 600;
    padding: 0 16px 0 8px !important;
    height: 40px;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 40px;
  }

  .mat-expansion-panel-body {
    padding: 0 16px 0;
  }

  .mat-list-text {
    padding-right: 0 !important;
  }

  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    height: 40px;
  }
}

gn-series-list-view .mat-expansion-panel {
  background-color: transparent;

  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
}

ls-general-category-product-search-form,
gn-category-search-side-nav-container {
  .mat-expansion-panel-body {
    padding: 0 16px 16px !important;
  }
}

.mat-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 10px !important;
  font-size: 13px;
}

.mat-menu-item-submenu-trigger {
  margin-right: 16px;
}

gn-search-box .mat-input-element {
  caret-color: $white;
}

gn-search-box .mat-input-element::placeholder {
  color: rgba($white, 0.48) !important;
}

ls-admin-root .mat-drawer {
  overflow-y: auto;
}

ls-admin-root .mat-drawer-inner-container {
  overflow: visible;
}

ls-admin-root .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: 0px;
}

.mat-expansion-panel-content {
  font:
    400 13px / 18px Roboto,
    'Helvetica Neue',
    sans-serif;
}

.app-spinner-wrap .mat-spinner circle {
  stroke: $white !important;
}

mat-card {
  // !importantないと4pxに上書きされてしまう
  border-radius: 8px !important;
}

.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: $box-shadow;
  transition: ease-in-out 0.2s;

  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
}

ls-checkbox-field .mat-checkbox-label {
  font-size: 13px;
}

.looks-search {
  .update-profile-dialog-container {
    .mat-dialog-container {
      padding: 24px;

      .mat-dialog-title {
        margin-bottom: 24px;
      }

      .mat-dialog-content {
        max-height: 70dvh;
      }
    }
  }
}

.looks-search {
  .sample-request-steps-dialog-container {
    .mat-dialog-container {
      height: 80dvh;
    }
  }

  .creating-project-dialog {
    .mat-dialog-container {
      overflow: hidden;
      position: relative;
      background-color: rgba($color: #fff, $alpha: 0.27);
      padding: 24px;

      .mat-dialog-title {
        margin-bottom: 24px;
      }

      .mat-dialog-content {
        max-height: 70dvh;
      }
    }
  }
}

[class^='mat-']:not(.mat-icon),
[class*=' mat-']:not(.mat-icon) {
  font-family: $font-sans-serif;
}

.tenant-library-list-dialog {
  .mat-content {
    overflow: visible;
  }
}
