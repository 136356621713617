// フォームフィールドの見た目調整
.mat-mdc-form-field {
  // テキスト位置調整
  .mat-mdc-form-field-infix {
    input.mat-mdc-input-element {
      height: 24px !important;
    }
  }
}

// menu-itemにdensityが設定できないため、メニューアイテムの高さ調整
.mat-mdc-menu-item {
  min-height: 36px !important;
}
