@use '@angular/material' as mat;

$trussprimary: (
  50: #e9f0f9,
  100: #c8dbf0,
  200: #a4c3e7,
  300: #80aadd,
  400: #6498d5,
  500: #4986ce,
  600: #427ec9,
  700: #3973c2,
  800: #3169bc,
  900: #2156b0,
  A100: #ebf2ff,
  A200: #b8d0ff,
  A400: #85afff,
  A700: #6b9eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$trussaccent: (
  50: #e5ecf4,
  100: #bfd0e5,
  200: #94b0d3,
  300: #6990c1,
  400: #4979b4,
  500: #2961a7,
  600: #24599f,
  700: #1f4f96,
  800: #19458c,
  900: #0f337c,
  A100: #aec6ff,
  A200: #7ba2ff,
  A400: #487dff,
  A700: #2f6bff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$trusswarn: (
  50: #ffebe4,
  100: #ffcdbd,
  200: #ffab91,
  300: #ff8964,
  400: #ff7043,
  500: #ff5722,
  600: #ff4f1e,
  700: #ff4619,
  800: #ff3c14,
  900: #ff2c0c,
  A100: #ffffff,
  A200: #fff7f6,
  A400: #ffc9c3,
  A700: #ffb2a9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$trusslime: (
  50: #f9f9e0,
  100: #f1f1b3,
  200: #e8e880,
  300: #dfdf4d,
  400: #d8d826,
  500: #d1d100,
  600: #cccc00,
  700: #c6c600,
  800: #c0c000,
  900: #b5b500,
  A100: #ffffde,
  A200: #ffffab,
  A400: #ffff78,
  A700: #ffff5e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Color Pallets
$ls-grey: mat.define-palette(mat.$grey-palette);
$ls-yellow: mat.define-palette(mat.$yellow-palette);
$ls-pink: mat.define-palette(mat.$pink-palette);
$ls-brown: mat.define-palette(mat.$brown-palette);
$ls-blue: mat.define-palette($trussprimary);
$ls-dblue: mat.define-palette($trussaccent);
$ls-teal: mat.define-palette(mat.$teal-palette);
$ls-orange: mat.define-palette($trusswarn);
$ls-deep-orange: mat.define-palette(mat.$deep-orange-palette);
$ls-lime: mat.define-palette($trusslime);

$ls-primary: $ls-blue;
$ls-accent: $ls-dblue;
$ls-warn: $ls-orange;

$base: #535353;
$basic-planning: #182a40;
$basic-main: #4986ce;
$basic-design: #2961a7;
$building-design: #3c99be;
$construction-work: #47b4be;
$completion: #87be47;
$incompletion: #707070;
$practice: #ffffff;
$order: #44ac97;

$primary: #182a40;
$primary-light: #2c3e55;
$white: #fff;
$lime: #a7cd3a;
$light-blue: #e7f3fd;
$blue: #2961a7;
$grey: #535353;
$link: rgb(0, 212, 255);

$picklist-deep-gray: #182a40;
$picklist-gray: #757575;
$picklist-middle-gray: #666666;
$picklist-bit-gray: #eeeeee;
$picklist-light-gray: #e0e0e0;
$picklist-white: #fafafa;
$picklist-deep-blue: #2882eb;
$picklist-blue: #55a1c7;
$picklist-orange: #f57c00;

$cell-hover-color: #e9f0f9;

$v2-base: #182a40;
$v2-white: #fff;
$v2-blue: #0725e6;
$v2-cyan: #07b2e6;
$v2-purple: #7a07e6;
$v2-indigo: #3007e6;
$v2-red: #e32a27;
$v2-orange: #e34b27;
$v2-light-orange: #f57c00;
$v2-pink: #d427e3;
$v2-gray: #e0e0e0;
$v2-bit-gray: #f8f8f8;

$box-shadow:
  rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
$box-shadow-hover:
  rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

$linear-gradient: linear-gradient(
  90deg,
  rgb(0, 212, 255) 0%,
  rgb(13, 13, 184) 80%,
  rgb(20, 11, 168) 100%
);
