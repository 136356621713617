@import 'variables/index';

@import '~normalize.css';

:root {
  color: map-get($ls-grey, 900);
  box-sizing: border-box;
  font-size: map-get($font-size, default);
  font-family: $font-sans-serif;
  background: map-get($ls-grey, 50);
}

* {
  box-sizing: border-box;
  font-size: inherit;
  color: inherit;
}

h1 {
  font-size: map-get($font-size, huge);
}
h2 {
  font-size: map-get($font-size, x-large);
}
h3 {
  font-size: map-get($font-size, large);
}
h4,
h5,
h6 {
  font-size: map-get($font-size, default);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0 0 0.8em;
  margin: 0;
  line-height: 1.5;
}

a {
  cursor: pointer;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

p {
  margin: 0;
  line-height: 1.5;

  &:not(:last-of-type) {
    padding-bottom: 0.5em;
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

.g-line-break-text {
  white-space: pre-wrap;
}

.convert-hyperlink {
  color: #4986ce;
  text-decoration: underline;
}

.no-scroll {
  overflow: hidden;
}

@import 'modules/index';
