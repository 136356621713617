@import '../variables/colors';

table.ls-table {
  border-collapse: collapse;
  border: 1px solid map-get($ls-grey, 200);
  width: 100%;

  tr {
    &:not(:last-of-type) {
      border-bottom: 1px solid map-get($ls-grey, 200);
    }
  }

  th,
  td {
    padding: 1em;
    text-align: left;
  }

  th {
    font-weight: bold;
    background-color: map-get($ls-grey, 100);
  }
}
