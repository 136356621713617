.mat-dialog-title {
  margin: 0;
  padding: 0;
}

.mat-dialog-container {
  padding: 0;
}

.mat-dialog-content {
  margin: 0;
  padding: 0;
  max-height: 95dvh;
}

.help-dialog-container {
  .mat-dialog-container {
    width: 470px;
    max-height: 369px;
    padding: 20px 20px 20px;
    position: absolute;
    top: 36%;
    left: 23%;
  }
}

.admin-report-popup {
  padding-bottom: 8px;
  background: #fff;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 50vh;
  min-width: 500px;
  .mat-list-item {
    font-size: 14px !important;
    &:hover,
    &:focus {
      background: #e8f0fe;
    }
  }
}
