@import '../variables/colors';
@import '../variables/font';

.ls-chip {
  display: inline-block;
  background: map-get($ls-grey, 300);
  padding: 0.6em 1em;
  border-radius: 2.2em;
  line-height: 1;

  @each $modifier, $size in $font-size {
    &--#{$modifier} {
      font-size: $size;
    }
  }
}
