@import '../../../scss/variables/colors.scss';
@import '../../../scss/mixins/media.scss';

.mat-form-field-flex {
  align-items: center;
}

.mat-form-field-prefix {
  margin-right: 10px;
}

.mat-form-field-suffix {
  margin-left: 10px;
}

.ConstructionConfirmationSymbol
  .mat-form-field-appearance-outline
  .mat-form-field-infix {
  display: grid;
  place-items: start;
}

.ConstructionConfirmationSymbol .mat-select-value-text {
  display: none !important;
}

.datepicker {
  width: 100%;
  .mat-form-field-infix {
    width: calc(100% - 35px) !important;
  }
}

.mat-input-element:disabled {
  color: $base;
}

.mat-select-disabled .mat-select-value {
  color: $base;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.6em 0 0.6em 0;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0.25em 0 0;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.32em 0;
}

.form-wrapper .mat-form-field-infix {
  @include tab {
    width: 120px;
  }
}

.picklist__form {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-select-panel .mat-option {
  height: fit-content;
  line-height: fit-content;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none;
}

.mat-select-arrow-wrapper {
  height: initial;
}

.mat-select-panel {
  max-width: 400px;
}

.mat-select-placeholder {
  color: inherit;
}

.mat-form-field-underline {
  display: none;
}

.cell__inner {
  .mat-form-field-wrapper {
    padding: 0;
  }
}

.cell__inner {
  input.mat-input-element {
    text-align: center;
  }
}

ls-add-joinery-option-card {
  .mat-form-field-infix {
    width: auto;
  }
}

.disabled-select .mat-select-arrow {
  visibility: hidden;
}
