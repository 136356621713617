.ls-section {
  display: block;
  padding-bottom: 30px;

  &.small {
    padding-bottom: 12px;
  }
  &.medium {
    padding-bottom: 30px;
  }
  &.large {
    padding-bottom: 50px;
  }
}
