@mixin sp {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tab {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 490px) {
    @content;
  }
}

@mixin revit {
  @media (max-width: 1279px) {
    @content;
  }
}
