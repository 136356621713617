$font-size: (
  huge: 36px,
  x-large: 24px,
  large: 18px,
  medium: 16px,
  default: 14px,
  small: 12px,
  x-small: 10px,
);

// 24/4/18 windowsでの表示確認のためにMeiryoを削除
$font-serif:
  Sawarabi Mincho,
  serif;
$font-sans-serif:
  Noto Sans JP,
  Noto Sans Japanese,
  sans-serif;
