gn-cropper {
  img {
    display: inline-block;
  }

  image-cropper {
    width: fit-content !important;
    padding: 0 !important;
  }
}
