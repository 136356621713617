$layout-width: (
  full: 100%,
  wide: 1920px,
  default: 1260px,
  slim: 960px,
);
$side-nav-width: 230px;
$mode-tag-width: 160px;
$admin-side-nav-width: 240px;

$global-nav-height: 52px;
$sub-header-height: 40px;
$footer-height: 50px;
$page-header-height: 42px;
$button-menu-height: 56px;
$menu-height: 40px;
$project-sub-header-height: 48px;
$breadcrubs-height: 48px;
$product-list-tabs-height: 46px;
$paginator-height: 56px;

// finish-schedule
$detail-cell-width: 120px;
$detail-cell-small-width: 80px;
$room-cell-width: 160px;
$floor-cell-width: 80px;
$row-height: 40px;
$browseMode-detail-cell-width: 240px;
$show-column-name-width: 71px;
$header-icon-size: 18px;
$show-column-width: calc(
  #{$header-icon-size} + #{$show-column-name-width} + 3px
);
$menu-actions-height: 73px;
